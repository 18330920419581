import { graphql } from "gatsby";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import { Resource } from "../classes/Resource";
// import TechResContent from "../components/ui/TechnicalResources/Content/TechResContent";
import get from "lodash/get";
// import { colors } from "../vars/palette";
import styled from "styled-components";
// import { BREAKPOINT_MEDIUM_MAX } from "../components/ui/Typography";
import FixedNav from "../../components/ui/TechnicalResources/Nav/FIxedNav";
import TechButtons from "../../components/ui/TechnicalResources/Buttons/TechButtons";
// import CalcThrustRestraint from "../components/calculators/kind/Thrust";
// import CalcSupports from "../components/calculators/kind/Supports";
// import CalcHydraulic from "../components/calculators/kind/Hydraulic";
import CalcThicknessDesign from "../../components/calculators/kind/Thickness";
import CalcLcca from "../../components/calculators/kind/LCCA/LCCA";
import TechResNav from "../../components/ui/TechnicalResources/Nav/TechResNav";
// import DipraBreadcrumbs from "../components/Layout/Breadcrumbs";
import { WmkSeo } from "wmk-seo";
import { Resource } from "../../classes/Resource";
import { colors } from "../../vars/palette";
import { BREAKPOINT_MEDIUM_MAX } from "../../components/ui/Typography";
import CalcThrustRestraint from "../../components/calculators/kind/Thrust";
import CalcDesignOnSupports from "../../components/calculators/kind/Supports";
import CalcHydraulic from "../../components/calculators/kind/Hydraulic";
import DipraBreadcrumbs from "../../components/Layout/Breadcrumbs";
import TechResContent from "../../components/ui/TechnicalResources/Content/TechResContent";
import { ResourceQuery } from "../../fragments/NodeResourceFields";
import { RolloverMenuFields } from "../../fragments/NodeMenuFields";
import { BlockIconCopyFields } from "../../fragments/NodeRichTextFields";
import IconCopy from "../../components/ui/RichText/Blocks/IconCopy";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
// import { ResourceQuery } from "../fragments/NodeResourceFields";
// import { RolloverMenuFields } from "../fragments/NodeMenuFields";

const NullComponent = () => <></>;

const StyledContainer = styled(Container)`
  & > .row {
    min-height: 100vh;
  }
  .nav-container {
    @media print {
      display: none;
    }
    background-color: ${colors.techNavGreen.hex};
    padding: 14vh 3rem;
    a {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .content-col {
    padding-right: 3rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    .nav-container {
      padding: 3vh 1.5rem;
      height: fit-content;
    }
    .content-col {
      padding-right: 0rem;
    }
    .image-padding-div {
      padding-right: 3rem;
    }
  }
`;

const ImageContainer = styled(Col)`
  padding: 0 20px 20px;
  .image-padding-div {
    padding-left: 8.33333333%;
  }

  @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const registeredCalculators = {
  "thrust-restraint-calculator": CalcThrustRestraint,
  "design-on-supports": CalcDesignOnSupports,
  "hydraulic-analysis-and-greenhouse-gas-emissions": CalcHydraulic,
  "thickness-design": CalcThicknessDesign,
  "life-cycle-cost-analysis": CalcLcca
};

/**
 *
 * @param {Object} pageContext
 * @param {Object} data
 * @returns {JSX}
 */
const Patent = ({
  pageContext,
  data
}: {
  pageContext: { parentPath: string; slug: string };
  data: ResourceNodeQuery;
}) => {
  const resource = new Resource(data.resource);
  const techMenus = data.techMenus.edges.map((e) => e.node);
  let menuParentSlug;
  const thisSlug = pageContext?.slug;
  const menu = techMenus.reduce((match, m) => {
    const parentSlug = m.menuParent?.resourceSlug;
    const nearest = resource.hierarchy.nearest().slug;

    if (parentSlug === thisSlug) {
      menuParentSlug = parentSlug;
      const childLinks = m.links.length ? m.links : [];
      return [...childLinks];
    } else {
      if (parentSlug === nearest) {
        menuParentSlug = parentSlug;
        const childLinks = m.links.length ? m.links : [];
        return [...childLinks];
      }
    }
    return match;
  }, []);
  const parent = resource.parent;
  const path = resource.parentPath;
  const slug = resource.slug;
  const isCalc = parent === `/calculators`;
  const CalcJsx = isCalc
    ? get(registeredCalculators, `${slug}`, NullComponent)
    : NullComponent;
  return (
    <>
      <WmkSeo.Meta
        title={resource.title}
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        slug={"/patent"}
        path={"/V-Bio"}
        description={resource.meta}
        ogImageUrl={resource.ogImage ? resource.ogImage : ""}
        twitterImageUrl={resource.twitterImage ? resource.twitterImage : ""}
        twitterHandle={data.site.siteMetadata.twitter}
      />
      <StyledContainer fluid>
        <Row style={{}}>
          <Col className="nav-container" xs={12} sm={12} md={5} lg={4}>
            <TechResNav
              resource={resource ? resource : undefined}
              menu={menu}
              slug={pageContext.slug}
            />
          </Col>
          <Col xs={12} sm={12} md={7} lg={8} className="content-col">
            {/* content-target div used to snap page down to the content when a resource nav item is clicked on mobile */}
            <div id="content-target" />
            <DipraBreadcrumbs route={`${path}/${slug}/V-Bio® Patents`} />
            {/* {isCalc ? <CalcJsx /> : <TechResContent resource={resource} />} */}
            <IconCopy
              copy={data.iconBlock.copy}
              headline={data.iconBlock.headline}
              icon={data.iconBlock.icon}
              iconColor={data.iconBlock.iconColor}
            />
            <ImageContainer>
              <Row>
                <Col>
                  <div className="image-padding-div">
                    <WmkImage image={new Img(data.image)} />
                  </div>
                </Col>
              </Row>
            </ImageContainer>
          </Col>
        </Row>
      </StyledContainer>
      <FixedNav parentSlug={menuParentSlug}>
        <TechButtons menu={menu} slug={pageContext.slug} />
      </FixedNav>
    </>
  );
};

export default Patent;

export interface ResourceNodeQuery {
  resource: ResourceQuery;
  techMenus: {
    edges: {
      node: RolloverMenuFields;
    }[];
  };
  iconBlock: BlockIconCopyFields;
  image: ContentfulImageQuery;
  site: {
    siteMetadata: {
      description: string;
      baseUrl: string;
      title: string;
      twitter: string;
    };
  };
}

export const query = graphql`
  {
    resource: contentfulResource(slug: { eq: "applying-encasements" }) {
      ...NodeResourceFields
      ...ResourceBlockIconCopy
      ...ResourceBlockImageCopy
      ...ResourceAsset
    }
    iconBlock: contentfulBlockIconCopy(
      blockId: {
        eq: "Technical Resources>Design Steps>Installation>Encasements>V-Bio® Patents"
      }
    ) {
      ...NodeBlockIconCopy
    }
    techMenus: allContentfulMenu(
      filter: { title: { regex: "/technical resources >/i" } }
    ) {
      edges {
        node {
          ...NodeMenuFields
        }
      }
    }
    image: contentfulAsset(title: { eq: "V-Bio-wrap-pipe web" }) {
      ...NodeImageFields
    }
    site {
      siteMetadata {
        baseUrl
        title
        twitter
      }
    }
  }
`;
